import React, {
  type ComponentPropsWithoutRef,
  type PropsWithChildren,
  type ReactElement,
} from 'react';

import useMediaQuery, { Breakpoints } from '../../utils/hooks/useMediaQuery';
import MainNavigationSidebar from '../MainNavigationSidebar';
import Navbar from '../Navbar';
import CoachingSessionAlertBannerWrapper from '../coaching-session/CoachingSessionAlertBanner';

type Props = Pick<
  ComponentPropsWithoutRef<typeof Navbar>,
  'hideInternalNavigation' | 'navLinks'
> &
  PropsWithChildren<{
    secondaryNavbar?: ReactElement;
  }>;

const PageContent: React.FC<Props> = ({
  hideInternalNavigation,
  secondaryNavbar,
  navLinks,
  children,
}) => {
  const isSm = useMediaQuery(Breakpoints.SM, false);

  const heightClassName = secondaryNavbar
    ? 'h-screen-below-navbar-and-secondary-navbar'
    : 'h-screen-below-navbar';

  return (
    <div className="flex min-h-screen flex-col-reverse">
      <div className={`flex ${heightClassName}`}>
        {!hideInternalNavigation && isSm ? <MainNavigationSidebar /> : null}
        <div className="grow">{children}</div>
      </div>
      <CoachingSessionAlertBannerWrapper />
      {secondaryNavbar}
      <Navbar
        hideInternalNavigation={hideInternalNavigation}
        navLinks={navLinks}
      />
    </div>
  );
};

export default PageContent;
