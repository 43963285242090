import {
  Breakpoints,
  getTimeDifference,
  IconArrowRight,
  MINUTE,
  TimeUnit,
  useMediaQuery,
} from '@leland-dev/leland-ui-library';
import Link from 'next/link';
import React, { useEffect, useMemo, useState } from 'react';

import {
  CoachingSessionUrgencyEnum,
  useCoachingSessionTimeRemaining,
} from '../../utils/coaching-session';
import { getUrlObject } from '../../utils/url';

import {
  type CoachingSessionAlertBannerClassFragment,
  type CoachingSessionAlertBannerCoachingSessionFragment,
  type CoachingSessionAlertBannerOneOnOneSessionFragment,
  useCoachingSessionAlertBannerUserQuery,
} from './__generated-gql-types__/CoachingSessionAlertBanner.generated';

interface CoachingSessionAlertBannerProps {
  session: CoachingSessionAlertBannerCoachingSessionFragment;
  onSessionEnd: () => void;
}

const isSessionClass = (
  session:
    | CoachingSessionAlertBannerOneOnOneSessionFragment
    | CoachingSessionAlertBannerClassFragment,
): session is CoachingSessionAlertBannerClassFragment => {
  return session?.__typename === 'Class';
};

const CoachingSessionAlertBanner: React.FC<CoachingSessionAlertBannerProps> = ({
  session,
  onSessionEnd,
}) => {
  const isMd = useMediaQuery(Breakpoints.MD);
  const sessionTitle = isSessionClass(session.content)
    ? session.content.name
    : session.content.title;
  const sessionId = session.id;

  const { urgency, isPast } = useCoachingSessionTimeRemaining(
    session.startAt,
    session.endAt,
  );

  const isNow = urgency === CoachingSessionUrgencyEnum.NOW;

  const sessionHrefObject = getUrlObject(`/meet/${sessionId}`, null);

  const title = isSessionClass(session.content)
    ? `Your ${session.content.isFreeEvent ? 'event' : 'class'} ${sessionTitle} is happening ${isNow ? 'now' : 'soon'}!`
    : `Your session${
        session.attendees?.[0]?.user?.firstName
          ? ` with ${session.attendees[0].user.firstName}`
          : ''
      } is happening ${isNow ? 'now' : 'soon'}!`;

  const sessionType = isSessionClass(session.content)
    ? session.content.isFreeEvent
      ? 'event'
      : 'class'
    : 'session';

  useEffect(() => {
    if (isPast) {
      onSessionEnd();
    }
    // re-check when `isPast` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPast]);

  return (
    <div className={isNow ? 'bg-leland-red/20' : 'bg-leland-blue-light'}>
      <div className="flex items-center justify-between space-x-4 px-4 py-3 lg:px-6">
        <p
          className={`text-left text-base font-medium ${isNow ? 'text-leland-red' : 'text-leland-blue'}`}
        >
          {title}
        </p>
        <Link
          href={sessionHrefObject}
          type="button"
          className={`flex items-center justify-center whitespace-nowrap rounded-lg px-2.5 py-2 text-sm font-medium text-white ${isNow ? 'bg-leland-red' : 'bg-leland-blue'}`}
        >
          {isMd ? (
            `Join ${sessionType}`
          ) : (
            <IconArrowRight className="size-3.5" />
          )}
        </Link>
      </div>
    </div>
  );
};

const CoachingSessionAlertBannerWrapper: React.FC = () => {
  const [recentlyEndedSessionId, setRecentlyEndedSessionId] =
    useState<string>();
  const [currTime, setCurrTime] = useState<number>(Date.now());

  const { data: userData } = useCoachingSessionAlertBannerUserQuery({
    pollInterval: 5 * MINUTE,
  });

  useEffect(() => {
    const interval = setInterval(
      // force re-render every minute to check if the next session has started
      () => setCurrTime(Date.now()),
      1 * MINUTE,
    );
    return () => clearInterval(interval);
  }, []);

  const nextSession = useMemo(
    () =>
      userData?.user?.coach?.upcomingEnrolledSessions?.find(
        (session) =>
          (!recentlyEndedSessionId || session.id !== recentlyEndedSessionId) &&
          getTimeDifference(currTime, session.startAt, TimeUnit.MINUTE) <= 15,
      ),
    [
      currTime,
      recentlyEndedSessionId,
      userData?.user?.coach?.upcomingEnrolledSessions,
    ],
  );

  if (!nextSession || nextSession.id === recentlyEndedSessionId) {
    return null;
  }

  return (
    <CoachingSessionAlertBanner
      session={nextSession}
      onSessionEnd={() => setRecentlyEndedSessionId(nextSession.id)}
    />
  );
};

export default CoachingSessionAlertBannerWrapper;
